<app-header-interno [background]="'assets/banner-interno-sobre.jpg'" [title]="'Sobre'"></app-header-interno>

<section class="sobre">
    <div class="container">
        <div class="sobre-wrapper">
            <div class="sobre__image">
                <img [src]="global.imageUrl+sobre.imagem" alt="Sobre nós">
            </div>
            <h2 class="sobre__title">
                Um pouco <b>sobre</b> nós
            </h2>
            <div class="sobre__description" [innerHtml]="html"></div>
        </div>
    </div>
    <div class="depoimentos" *ngIf="depoimentos.length">
        <div class="container">
            <div class="depoimentos__title title title-default">
                <h2>Depoimentos</h2>
            </div>
            <ngu-carousel #depoimentosCarousel [inputs]="carouselBanner" [dataSource]="depoimentos"
                class="depoimentos-carousel">
                <ngu-item NguCarouselItem class="depoimentos-item" *nguCarouselDef="let item; let i = index">
                    <div class="depoimentos-item__person">
                        <div class="depoimentos-item__avatar">
                            <img [src]="item.imagem ? global.imageUrl+item?.imagem : 'assets/placeholder-person.jpg'" [alt]="item.nome">
                        </div>
                        <h4 class="depoimentos-item__name">
                            <strong>{{item.nome}}</strong> / {{item.cargo}}
                        </h4>
                    </div>
                    <div class="depoimentos-item__description">
                        "{{item.descricao}}"
                    </div>
                </ngu-item>
                <ul class="depoimentos-carousel-dots" NguCarouselPoint>
                    <li *ngFor="let point of depoimentosCarousel.pointNumbers; let i = index"
                        [class.active]="i == depoimentosCarousel.activePoint" (click)="depoimentosCarousel.moveTo(i)"
                        class="depoimentos-carousel-dots__item">
                    </li>
                </ul>
            </ngu-carousel>
        </div>
    </div>

    <div class="clients" *ngIf="fornecedores.length">
        <div class="container">
            <div class="clients-wrapper">
                <a class="clients-item" *ngFor="let f of fornecedores; let i = index;" [title]="f.nome" [href]="f.link"
                    target="_blank" [class.no-click]="!f.link">
                    <img [src]="f.imagem ? global.imageUrl+f.imagem : 'assets/placeholder-person.jpg'" [alt]="f.nome" [title]="f.nome" loading="lazy">
                </a>
            </div>
        </div>
    </div>
</section>