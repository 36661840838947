<footer class="footer">
    <div class="container">
        <div class="footer-wrapper">

            <div class="col">
                <div class="logo">
                    <img src="assets/logo.png" alt="FOQUEBEM Óptica">
                </div>
            </div>

            <div class="col">
                <h4 class="footer-title">
                    Produtos
                </h4>
                <ul class="footer-menu">
                    <li class="footer-menu__item" *ngFor="let c of categorias">
                        <a class="footer-menu__link" [title]="c.nome"
                            [routerLink]="['/produtos', c.slug]">{{c.nome}}</a>
                    </li>
                </ul>
            </div>

            <div class="col">
                <h4 class="footer-title">
                    Institucional
                </h4>
                <ul class="footer-menu">
                    <li class="footer-menu__item">
                        <a routerLink="/sobre" class="footer-menu__link" title="Sobre">Sobre</a>
                    </li>
                    <li class="footer-menu__item">
                        <a routerLink="/blog" class="footer-menu__link" title="Blog">Blog</a>
                    </li>
                    <li class="footer-menu__item">
                        <a routerLink="/contato" class="footer-menu__link" title="Blog">Contato</a>
                    </li>
                </ul>
            </div>

            <div class="col">
                <h4 class="footer-title">
                    Entre em contato
                </h4>
                <div class="footer-link">
                    <i class="fas fa-map-marker-alt"></i>
                    <span class="footer-link__label">{{contatoGeral.cep | mask: '00000-000'}}, {{contatoGeral.rua}},
                        {{contatoGeral.numeroEndereco}}, {{contatoGeral.bairro}}, Brasil -
                        {{contatoGeral.cidade}}/{{contatoGeral.uf}}
                        - RS</span>

                </div>
                <div class="footer-link">
                    <i class="fas fa-phone-alt"></i>
                    <a [href]="'tel:55'+contatoGeral.telefone" title="phone" class="footer-link__label">
                        {{contatoGeral.telefone | mask: contatoGeral.telefone.length == 11 ? '(00) 00000-0000' : '(00) 0000-0000'}}
                    </a>
                </div>
                <div class="footer-link">
                    <i class="fab fa-whatsapp"></i>
                    <a [href]="'https://wa.me/55'+contatoGeral.whatsApp" target="_blank" title="whatsapp"
                        class="footer-link__label">
                        {{contatoGeral.whatsApp | mask: '(00) 00000-0000'}}</a>
                </div>
                <div class="footer-link">
                    <i class="fas fa-envelope"></i>
                    <a [href]="'mailto:'+contatoGeral.email" title="email"
                        class="footer-link__label">{{contatoGeral.email}}</a>
                </div>
            </div>

            <div class="col">
                <h4 class="footer-title">
                    Redes sociais
                </h4>
                <ul class="footer-social">
                    <li class="footer-social__item" *ngIf="contatoGeral.linkFacebook">
                        <a [href]="contatoGeral.linkFacebook" target="_blank" title="Facebook"
                            class="footer-social__link">
                            <i class="fab fa-facebook-square"></i>
                        </a>
                    </li>
                    <li class="footer-social__item" *ngIf="contatoGeral.linkInstagram">
                        <a [href]="contatoGeral.linkInstagram" target="_blank" title="Instagram"
                            class="footer-social__link">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="footer-bottom">
        <div class="container">
            <div class="wp">
                <span class="footer-copyrights">
                    2020 &copy; Optica Skalla.
                </span>
                <div class="footer-logos">
                    <a href="https://yourway.digital" target="_blank">
                        <img src="assets/yourway.png" alt="YourWay digital">
                    </a>
                </div>
            </div>
        </div>
    </div>
</footer>