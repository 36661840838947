import { Categoria } from './../../models/categoria.model';
import { ContatoGeral } from './../../models/contato-geral.model';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  @Input('contatoGeral') contatoGeral: ContatoGeral;
  @Input('categorias') categorias: Categoria[];

  constructor() { }

  ngOnInit() {
  }

}
