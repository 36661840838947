import { AlertService } from './../../services/alert.service';
import { LoadingService } from './../../services/loading.service';
import { Subscription } from 'rxjs';
import { Mensagem } from './../../models/mensagem.model';
import { NgForm } from '@angular/forms';
import { HelperService } from './../../services/helper.service';
import { SiteService } from './../../services/site.service';
import { ContatoGeral } from './../../models/contato-geral.model';
import { Component, AfterViewInit, OnInit, ViewChild, ElementRef } from '@angular/core';
import gtagService from 'src/app/lib/gtag';

@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.scss']
})
export class ContatoComponent implements OnInit, AfterViewInit {

  @ViewChild('mapContainer') gmap: ElementRef;
  map: google.maps.Map;

  mensagem: Mensagem = new Mensagem();
  contatoGeral: ContatoGeral = new ContatoGeral();

  buscarContatoGeralSubscription: Subscription;

  phoneMask: string = '(00) 0000-00009';

  constructor(
    public siteService: SiteService,
    public helper: HelperService,
    public loadingService: LoadingService,
    public alertService: AlertService
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.buscarContatoGeral();
  }

  ngOnDestroy() {
    this.buscarContatoGeralSubscription.unsubscribe();
  }

  enviarMensagem(f: NgForm) {
    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      return;
    }

    this.loadingService.present();

    this.siteService.inserirContatoMensagem(this.mensagem)
      .subscribe((res: any) => {
        this.alertService.present('Mensagem enviada', 'Em breve entraremos em contato.');
        f.reset();
        this.loadingService.dismiss();

        gtagService?.event({
          action: "form_complet_submitted",
          category: "generate_lead",
          label: "Formúlario Home",
          value: JSON.stringify(this.mensagem),
        });

      }, e => {
        this.alertService.present('Não foi possível enviar a mensagem!', 'Desculpe, mas não foi possível enviar a mensagem. Tente novamente outra hora.');
        this.loadingService.dismiss();
      })
  }

  changePhoneMask(event) {
    let val = event;
    if (!val) {
      return;
    }
    if (val.replace(/\D/g, '').substring(0, 4) == "0800") {
      this.phoneMask = '0000 000 0000';
    } else {
      if (val.replace(/\D/g, '').length === 11) {
        this.phoneMask = '(00) 0 0000-0000';
      } else {
        this.phoneMask = '(00) 0000-00009';
      }
    }
  }

  buscarContatoGeral() {
    this.loadingService.present();
    this.buscarContatoGeralSubscription = this.siteService.buscarContatoGeral()
      .subscribe((res: ContatoGeral) => {
        this.contatoGeral = res;
        this.mapInitializer(res.latitude, res.longitude);
        this.loadingService.dismiss();
      }, e => this.loadingService.dismiss());
  }

  mapInitializer(lat, lng) {

    const coordinates = new google.maps.LatLng(lat, lng);

    const mapOptions: google.maps.MapOptions = {
      center: coordinates,
      zoom: 18,
      styles: [
        {
          "featureType": "all",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#9c9c9c"
            }
          ]
        },
        {
          "featureType": "all",
          "elementType": "labels.text",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "landscape",
          "elementType": "all",
          "stylers": [
            {
              "color": "#f2f2f2"
            }
          ]
        },
        {
          "featureType": "landscape",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#ffffff"
            }
          ]
        },
        {
          "featureType": "landscape.man_made",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#ffffff"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "all",
          "stylers": [
            {
              "saturation": -100
            },
            {
              "lightness": 45
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#eeeeee"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#7b7b7b"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#ffffff"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "simplified"
            }
          ]
        },
        {
          "featureType": "road.arterial",
          "elementType": "labels.icon",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "transit",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "all",
          "stylers": [
            {
              "color": "#46bcec"
            },
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#c8d7d4"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#070707"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#ffffff"
            }
          ]
        }
      ]
    };

    const marker = new google.maps.Marker({
      position: coordinates,
      map: this.map,
    });

    this.map = new google.maps.Map(
      this.gmap.nativeElement,
      mapOptions
    );
    marker.setMap(this.map);
  }

}
