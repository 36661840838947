import { HelperService } from "./../../services/helper.service";
import { SiteService } from "./../../services/site.service";
import { Subscription } from "rxjs";
import { Produto } from "./../../models/produto.model";
import { ActivatedRoute } from "@angular/router";
import { Component, OnInit, HostListener } from "@angular/core";
import { GlobalService } from "src/app/services/global.service";

interface BuscarApi {
  numeroPaginas: number;
  produtos: Produto[];
  totalRegistros: number;
}

@Component({
  selector: "app-buscar",
  templateUrl: "./buscar.component.html",
  styleUrls: ["./buscar.component.scss", "../produtos/produtos.component.scss"],
})
export class BuscarComponent implements OnInit {
  produtos: Produto[] = [];
  openFilter: boolean = false;

  loading: boolean = true;
  noMore: boolean = false;
  page = 1;
  ordenacao = "5";
  total: number = 0;

  filtro: string = "";
  genero: string = "";
  generos = [
    { id: "genero_masculino", name: "Masculino", value: "M" },
    { id: "genero_feminino", name: "Feminino", value: "F" },
    { id: "genero_infantil", name: "Infantil", value: "I" },
    { id: "genero_unisex", name: "Unisex", value: "U" },
  ];

  buscarProdutosSubscription: Subscription;

  @HostListener("window:scroll", [])
  onScroll(): void {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      // you're at the bottom of the page
      if (this.loading || this.noMore) {
        return;
      }
      this.loadMore();
    }
  }

  constructor(
    public siteService: SiteService,
    public helper: HelperService,
    public route: ActivatedRoute,
    public global: GlobalService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((param) => {
      this.produtos = [];
      this.page = 1;
      this.noMore = false;
      this.ordenacao = "1";
      this.filtro = param.search;
      this.buscarProdutos();
    });
  }

  ngOnDestroy() {
    this.buscarProdutosSubscription.unsubscribe();
  }

  buscarProdutos() {
    if (
      this.buscarProdutosSubscription &&
      !this.buscarProdutosSubscription.closed
    ) {
      this.buscarProdutosSubscription.unsubscribe();
    }

    if (this.noMore) {
      return;
    }

    this.loading = true;
    this.buscarProdutosSubscription = this.siteService
      .buscarProdutos(
        this.page,
        9,
        parseInt(this.ordenacao),
        "",
        "",
        this.genero,
        null,
        null,
        null,
        this.filtro?.toLocaleLowerCase()
      )
      .subscribe(
        (res: BuscarApi) => {
          if (!res.produtos.length) {
            this.noMore = true;
          }
          this.produtos =
            this.page == 1 ? res.produtos : this.produtos.concat(res.produtos);
          this.total = res.totalRegistros;
          this.loading = false;
        },
        (e) => (this.loading = false)
      );
  }

  onOrdenacaoChange(e) {
    this.buscarProdutos();
  }

  loadMore() {
    this.page++;
    this.buscarProdutos();
  }

  onChangeGenero(ev) {
    if (!ev) return;
    this.page = 1;
    this.noMore = false;
    this.buscarProdutos();
  }

  clearFilter(filter) {
    this.page = 1;
    this.noMore = false;
    if (filter === "genero") {
      this.genero = "";
      this.buscarProdutos();
    }
  }
}
