<app-header-interno [background]="'assets/banner-interno-blog.jpg'" [title]="'Blog'"></app-header-interno>

<section class="blog">
    <div class="container">
        <div class="category">
            <div class="category-item">
                <input type="radio" name="tags" (change)="onTagChange($event.target.value)" id="%" value="%" checked>
                <label for="%">Todos</label>
            </div>
            <div class="category-item" *ngFor="let t of tags;">
                <input type="radio" name="tags" (change)="onTagChange($event.target.value)" [id]="t" [value]="t">
                <label [for]="t">{{t}}</label>
            </div>
        </div>

        <div class="blog-list" @list>
            <div class="blog-item" *ngFor="let n of noticias; let i = index;" @items>
                <a [routerLink]="['/blog', n.slug]" class="blog-item__image" [title]="n.titulo">
                    <img [src]="global.imageUrl+n.imagemPequena" [alt]="n.titulo">
                </a>
                <div class="blog-item__content">
                    <h3 class="blog-item__title">
                        {{n.titulo}}
                    </h3>
                    <p class="blog-item__description">
                        {{n.descricaoSimples | slice:0:60}}{{n.descricaoSimples.length >= 60 ? '...' : ''}}
                    </p>
                    <a [routerLink]="['/blog', n.slug]" class="btn btn-small btn-default">VEJA MAIS</a>
                </div>
            </div>
        </div>

        <app-loading-list *ngIf="loading"></app-loading-list>

        <div class="blog-button" *ngIf="!noMore && !loading && noticias.length >= 8">
            <button class="btn btn-outlined">VEJA MAIS</button>
        </div>
    </div>
</section>