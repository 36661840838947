import { Categoria } from "./../../models/categoria.model";
import { Produto } from "./../../models/produto.model";
import { LoadingService } from "./../../services/loading.service";
import { HelperService } from "./../../services/helper.service";
import { SiteService } from "./../../services/site.service";
import { Component, OnInit, HostListener } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  trigger,
  style,
  transition,
  animate,
  query,
  stagger,
  animateChild,
} from "@angular/animations";
import { GlobalService } from "src/app/services/global.service";
import { LabelType, Options } from "@angular-slider/ngx-slider";

interface ProdutosApi {
  numeroPaginas: number;
  produtos: Produto[];
  totalRegistros: number;
}

@Component({
  selector: "app-produtos",
  templateUrl: "./produtos.component.html",
  styleUrls: ["./produtos.component.scss"],
  animations: [
    trigger("items", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate("300ms ease-in-out"),
      ]),
    ]),
    trigger("list", [
      transition(":enter", [
        query("@items", stagger(300, animateChild()), { optional: true }),
      ]),
    ]),
  ],
})
export class ProdutosComponent implements OnInit {
  @HostListener("window:scroll", [])
  onScroll(): void {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      if (this.loading || this.noMore) {
        return;
      }
      this.loadMore();
    }
  }

  produtos: Produto[] = [];
  totalProdutos: number = 0;
  openFilter: boolean = false;

  page: number = 1;
  ordenacao: string = "5";
  noMore: boolean = false;
  loading: boolean = true;

  categoria: Categoria = new Categoria();

  genero: string = "";
  generos = [
    { id: "genero_masculino", name: "Masculino", value: "M" },
    { id: "genero_feminino", name: "Feminino", value: "F" },
    { id: "genero_infantil", name: "Infantil", value: "I" },
    { id: "genero_unisex", name: "Unisex", value: "U" },
  ];

  slugCategoria: string = "";
  slugSubcategoria: string = "";

  hideTitle: boolean = false;
  titulo: string = "Produtos";
  imagemFundo: string = "assets/banner-interno.jpg";

  valorMax: number;
  valorMin: number;
  marcas: { label: string; checked: boolean }[] = [];
  marcasSelected: string[] = [];
  showMoreMarcas = false;

  options: Options = {
    floor: 0,
    ceil: 0,
    translate: (value: number, label: LabelType): string => {
      const format = {
        minimumFractionDigits: 2,
        style: "currency",
        currency: "BRL",
      };
      switch (label) {
        case LabelType.Low:
          return value.toLocaleString("pt-BR", format);
        case LabelType.High:
          return value.toLocaleString("pt-BR", format);
        default:
          return value.toLocaleString("pt-BR", format);
      }
    },
  };

  constructor(
    public siteService: SiteService,
    public helperService: HelperService,
    public loadingService: LoadingService,
    public route: ActivatedRoute,
    public global: GlobalService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((param) => {
      this.options.ceil = 0;
      this.options.floor = 0;
      this.genero = "";
      this.page = 1;
      this.noMore = false;
      this.imagemFundo = "assets/banner-interno.jpg";
      this.slugCategoria = param.slugCategoria;
      this.slugSubcategoria = param.slugSubcategoria;
      if (this.slugCategoria && this.slugSubcategoria) {
        this.buscarSubcategoria(param.slugCategoria, param.slugSubcategoria);
      } else if (this.slugCategoria) {
        this.buscarCategoria(param.slugCategoria);
      }
      this.buscarMinMax();
      this.bucsarMarcas();
      this.buscarProdutos();
    });
  }

  ngOnDestroy() {
    this.helperService.updateTags();
  }

  buscarMinMax() {
    this.siteService
      .buscarMinMaxValor(
        "",
        this.slugCategoria,
        this.slugSubcategoria ?? "",
        this.genero
      )
      .subscribe((res) => {
        this.valorMin = res[0];
        this.valorMax = res[1];
        this.options.floor = res[0];
        this.options.ceil = res[1];
        console.log(this.valorMax, this.valorMin);
      });
  }

  bucsarMarcas() {
    this.siteService
      .buscarMarcas(
        "",
        this.slugCategoria,
        this.slugSubcategoria ?? "",
        this.genero
      )
      .subscribe(
        (res) =>
          (this.marcas = res.map((item) => ({
            label: item,
            checked: false,
          })))
      );
  }

  buscarCategoria(slugCategoria) {
    this.siteService.buscarCategoria(slugCategoria).subscribe((res) => {
      this.hideTitle = res?.mostraTitulo === "N";
      this.titulo = res.nome;
      if (res.imagemInterna) {
        this.imagemFundo = this.global.imageUrl + res.imagemInterna;
      }
      this.helperService.updateTags({
        nome: `${this.titulo} - Produtos - FOQUEBEM Óptica`,
        descricaoSimples:
          "Relógios, Semi-Joias, Alianças de Ouro e Consertos em Geral.",
        imagem: this.imagemFundo,
      });
    });
  }

  buscarSubcategoria(slugCategoria, slugSubcategoria) {
    this.siteService
      .buscarSubcategoria(slugCategoria, slugSubcategoria)
      .subscribe((res) => {
        this.hideTitle = res?.mostraTitulo === "N";
        this.titulo = `${res.nome} - ${res?.categoria?.nome}`;
        if (res.imagemInterna) {
          this.imagemFundo = this.global.imageUrl + res.imagemInterna;
        } else if (res?.categoria?.imagemInterna) {
          this.imagemFundo =
            this.global.imageUrl + res?.categoria?.imagemInterna;
        }
        this.helperService.updateTags({
          nome: `${this.titulo} - Produtos - FOQUEBEM Óptica`,
          descricaoSimples:
            "Relógios, Semi-Joias, Alianças de Ouro e Consertos em Geral.",
          imagem: this.imagemFundo,
        });
      });
  }

  buscarProdutos() {
    if (this.noMore) {
      return;
    }
    this.loading = true;
    this.siteService
      .buscarProdutos(
        this.page,
        9,
        parseInt(this.ordenacao),
        this.slugCategoria,
        this.slugSubcategoria,
        this.genero,
        this.valorMin,
        this.valorMax,
        this.marcasSelected.map((item) => `'${item}'`).join()
      )
      .subscribe(
        (res: ProdutosApi) => {
          if (!res.produtos.length || res.produtos.length < 9) {
            this.noMore = true;
          }
          this.produtos =
            this.page == 1 ? res.produtos : this.produtos.concat(res.produtos);
          this.totalProdutos = res.totalRegistros;
          this.loading = false;
        },
        (e) => (this.loading = false)
      );
  }

  onOrdenacaoChange(e) {
    this.reset();
    this.ordenacao = e;
    this.buscarProdutos();
  }

  loadMore() {
    this.page++;
    this.buscarProdutos();
  }

  reset() {
    this.page = 1;
    this.noMore = false;
    this.ordenacao = "1";
    this.loading = false;
  }

  onChagenMarca(ev) {
    this.page = 1;
    this.noMore = false;
    if (ev.checked) {
      this.marcasSelected.push(ev.label);
    } else if (!ev.checked && this.marcasSelected.some((item) => ev?.label)) {
      const index = this.marcasSelected.indexOf(ev.label);
      this.marcasSelected.splice(index, 1);
    }
    this.buscarMinMax();
    this.buscarProdutos();
  }

  onChangeGenero(ev) {
    if (!ev) return;
    this.page = 1;
    this.noMore = false;
    this.buscarProdutos();
  }

  clearFilter(filter: "genero" | "marca") {
    this.page = 1;
    this.noMore = false;
    if (filter === "genero") {
      this.genero = "";
    }
    if (filter === "marca") {
      this.marcasSelected = [];
    }
    this.buscarProdutos();
  }

  onSliderChange(ev) {
    this.noMore = false;
    this.page = 1;
    this.valorMin = ev.value;
    this.valorMax = ev.highValue;
    this.buscarProdutos();
  }
}
