import { BuscarComponent } from "./pages/buscar/buscar.component";
import { SobreComponent } from "./pages/sobre/sobre.component";
import { ContatoComponent } from "./pages/contato/contato.component";
import { BlogInternoComponent } from "./pages/blog-interno/blog-interno.component";
import { BlogComponent } from "./pages/blog/blog.component";
import { ProdutoInternoComponent } from "./pages/produto-interno/produto-interno.component";
import { ProdutosComponent } from "./pages/produtos/produtos.component";
import { HomeComponent } from "./pages/home/home.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

const routes: Routes = [
  { path: "", redirectTo: "/inicio", pathMatch: "full" },
  { path: "inicio", component: HomeComponent },
  { path: "sobre", component: SobreComponent },
  { path: "blog", component: BlogComponent },
  { path: "blog/:slug", component: BlogInternoComponent },
  { path: "produtos/:slugCategoria", component: ProdutosComponent },
  { path: "produtos/:slugCategoria/:slugSubcategoria", component: ProdutosComponent },
  { path: "produto/:slug", component: ProdutoInternoComponent },
  { path: "contato", component: ContatoComponent },
  { path: "buscar", component: BuscarComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    scrollPositionRestoration: "enabled",
    relativeLinkResolution: 'legacy'
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
