import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-header-interno",
  templateUrl: "./header-interno.component.html",
  styleUrls: ["./header-interno.component.scss"],
})
export class HeaderInternoComponent implements OnInit {
  @Input("background") background: string;
  @Input("title") title: string;
  @Input("hideTitle") hideTitle: boolean = false;

  constructor() {}

  ngOnInit() {}
}
