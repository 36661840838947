import { HelperService } from "./../../services/helper.service";
import { LoadingService } from "./../../services/loading.service";
import { SiteService } from "./../../services/site.service";
import { Subscription } from "rxjs";
import { Noticia } from "./../../models/noticia.model";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SafeHtml, DomSanitizer, Title, Meta } from "@angular/platform-browser";
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from "@angular/animations";

@Component({
  selector: "app-blog-interno",
  templateUrl: "./blog-interno.component.html",
  styleUrls: ["./blog-interno.component.scss"],
  animations: [
    trigger("simpleFadeAnimation", [
      state("in", style({ opacity: 1 })),
      transition(":enter", [style({ opacity: 0 }), animate(300)]),
      transition(":leave", animate(300, style({ opacity: 0 }))),
    ]),
  ],
})
export class BlogInternoComponent implements OnInit {
  shareUrl: string = "https://foquebem.com.br";

  noticia: Noticia = new Noticia();
  noticias: Noticia[] = [];

  html: SafeHtml;

  buscarNoticiaSubscription: Subscription;
  buscarNoticiasSubscription: Subscription;

  constructor(
    public siteService: SiteService,
    public router: Router,
    public route: ActivatedRoute,
    public loadingService: LoadingService,
    public helper: HelperService,
    public sanitizer: DomSanitizer,
    public title: Title,
    public meta: Meta
  ) {}

  ngOnInit() {
    this.shareUrl += this.router.url;
    this.route.params.subscribe((param) => this.buscarNoticia(param.slug));
    this.helper.setCanonicalURL();
  }

  ngOnDestroy() {
    this.buscarNoticiaSubscription.unsubscribe();
    this.buscarNoticiasSubscription.unsubscribe();
    this.helper.updateTags();
  }

  buscarNoticia(slug: string) {
    this.loadingService.present();
    this.buscarNoticiaSubscription = this.siteService
      .buscarNoticia(slug)
      .subscribe(
        (res: Noticia) => {
          this.noticia = res;
          this.html = this.sanitizer.bypassSecurityTrustHtml(res.descricao);
          this.helper.updateTags({
            nome: `${res.nome} - FOQUEBEM Óptica`,
            descricaoSimples: res.descricao,
            imagem: res.imagemPequena,
          });
          this.buscarNoticias(res.id);
          this.loadingService.dismiss();
        },
        (e) => this.loadingService.dismiss()
      );
  }

  buscarNoticias(id: number) {
    this.buscarNoticiasSubscription = this.siteService
      .buscarUltimasNoticias(1, 3, id, "%")
      .subscribe((res: Noticia[]) => (this.noticias = res));
  }
}
