interface EventProps {
  action: string;
  category: string;
  label: string;
  value: string;
}

declare const gtag: Function; // <------------Important: the declartion for gtag is required!

const gtagService = {
  // https://developers.google.com/analytics/devguides/collection/gtagjs/pages
  pageview: (url: string) => {
    try {
      gtag("config", process.env.GA_TRACKING_ID, {
        page_path: url,
      });
    } catch (error) {
      throw new Error("Não foi possível utilizar o gtag.pageview");
    }
  },

  // https://developers.google.com/analytics/devguides/collection/gtagjs/events
  event: ({ action, category, label, value }: EventProps) => {
    try {
      gtag("event", action, {
        event_category: category,
        event_label: label,
        value: value,
      });
    } catch (error) {
      throw new Error("Não foi possível utilizar o gtag.event");
    }
  },
};

export default gtagService;
