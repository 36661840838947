import { HelperService } from './../../services/helper.service';
import { LoadingService } from './../../services/loading.service';
import { SiteService } from './../../services/site.service';
import { Subscription } from 'rxjs';
import { Noticia } from './../../models/noticia.model';
import { Component, OnInit } from '@angular/core';
import { trigger, style, transition, animate, query, stagger, animateChild } from '@angular/animations';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss'],
  animations: [
    trigger('items', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-in-out')
      ]),
    ]),
    trigger('list', [
      transition(':enter', [
        query('@items', stagger(300, animateChild()), { optional: true })
      ]),
    ])
  ]
})
export class BlogComponent implements OnInit {

  noticias: Noticia[] = [];
  tags: any[] = [];

  buscarNoticiasSubscription: Subscription;

  page: number = 1;
  noMore: boolean = false;
  loading: boolean = false;

  tag: string = '%';

  constructor(
    public siteService: SiteService,
    public loadingService: LoadingService,
    public helper: HelperService,
    public global: GlobalService
  ) { }

  ngOnInit() {
    this.buscarNoticias();
    this.buscarTags();
    this.helper.setCanonicalURL();
  }

  ngOnDestroy() {
    this.buscarNoticiasSubscription.unsubscribe();
  }

  buscarNoticias() {

    if (this.buscarNoticiasSubscription && !this.buscarNoticiasSubscription.closed) {
      this.buscarNoticiasSubscription.unsubscribe();
    }

    if (this.noMore) {
      return;
    }

    this.loading = true;
    this.buscarNoticiasSubscription = this.siteService.buscarNoticias(this.page, 8, this.tag)
      .subscribe((res: Noticia[]) => {
        if (!res.length) {
          this.noMore = true;
        }
        this.noticias = this.page == 1 ? res : this.noticias.concat(res);
        this.loading = false;
      }, e => this.loading = false);
  }

  buscarTags() {
    this.siteService.buscarNoticiasTag().subscribe((res: any) => this.tags = res);
  }

  onTagChange(tag) {
    this.tag = tag;
    this.noMore = false;
    this.page = 1;
    this.buscarNoticias();
  }

  loadMore() {
    this.page++;
    this.buscarNoticias();
  }

}
