<app-header-interno
  [background]="'assets/banner-interno.jpg'"
  [title]="produto.nome"
>
</app-header-interno>

<section class="produto-interno">
  <div class="container">
    <div class="produto-interno-wrapper">
      <div class="produto-interno-image">
        <div class="gallery">
          <lib-ngx-image-zoom
            [thumbImage]="helper.renderImage(imageSelected.imagem, 500)"
            [fullImage]="helper.renderImage(imageSelected.imagem, 1000)"
            maxZoomRatio="1.2"
            class="gallery-principal"
          >
          </lib-ngx-image-zoom>
          <div class="gallery-carousel">
            <div
              class="gallery-carousel__item"
              *ngFor="let image of produto.imagens; let i = index"
              [class.active]="imageSelected.id == image.id"
              (click)="imageSelected = image"
            >
              <img
                [src]="helper.renderImage(image.imagem, 150)"
                [alt]="'Galeria' + i"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="produto-interno-content">
        <h2 class="produto-interno__title">
          {{ produto.nome }}
        </h2>
        <div class="produto-interno__price" *ngIf="produto.valorPromocao > 0">
          <strong>
            <s *ngIf="produto.valorPromocao > 0">{{
              produto.valor | currency: "BRL"
            }}</s>
            {{ produto.valorPromocao | currency: "BRL" }}
          </strong>
        </div>
        <div class="produto-interno__price" *ngIf="produto.valorPromocao === 0">
          <strong>
            {{ produto.valor | currency: "BRL" }}
          </strong>
        </div>
        <div class="produto-interno__description">
          {{ produto.marca | slice: 0:64
          }}{{ produto.marca.length >= 64 ? "..." : "" }}
        </div>
        <a
          class="produto-interno__link btn btn-default"
          (click)="openWhatsapp()"
          *ngIf="link"
        >
          COMPRAR AGORA
        </a>
      </div>
    </div>

    <div class="produto-interno-about">
      <div class="produto-interno-about__title title title-default">
        <h2>Sobre</h2>
        <span>Um pouco sobre esse produto</span>
      </div>
      <div
        class="produto-interno-about__description summernote"
        [innerHtml]="html"
      ></div>
    </div>
  </div>
  <section class="related-products" *ngIf="produtos.length">
    <div class="related-products__title title title-default">
      <h2>Relacionados</h2>
      <span>Produtos que você também vai gostar</span>
    </div>
    <div class="container">
      <div class="products-list">
        <article
          class="products-item"
          *ngFor="let p of produtos; let i = index"
        >
          <a
            class="products-item__image"
            [routerLink]="['/produto', p.slug]"
            [title]="p.nome"
          >
            <img [src]="global.imageUrl + p.imagem" [alt]="p.nome" />
          </a>
          <div class="products-item__content">
            <h3 class="products-item__title">
              {{ p.nome }}
            </h3>
            <strong class="products-item__price" *ngIf="p.valor > 0">
              <ng-container *ngIf="p.valorPromocao && p.valorPromocao > 0">
                <small>{{ p.valor | currency: "BRL" }}</small>
                {{ p.valorPromocao | currency: "BRL" }}
              </ng-container>
              <ng-container *ngIf="!p.valorPromocao || p.valorPromocao == 0">
                {{ p.valor | currency: "BRL" }}
              </ng-container>
            </strong>
            <p class="products-item__description">
              {{ p.marca | slice: 0:64 }}{{ p.marca.length >= 64 ? "..." : "" }}
            </p>
            <a
              [routerLink]="['/produto', p.slug]"
              class="btn btn-default btn-small btn-block"
            >
              VEJA MAIS
            </a>
          </div>
        </article>
      </div>
      <div
        class="related-products__button"
        *ngIf="produto.subcategorias.length"
      >
        <a
          [routerLink]="[
            '/produtos',
            produto.subcategorias[0].subcategoria.categoria.slug
          ]"
          class="btn btn-outlined"
        >
          ver mais
        </a>
      </div>
    </div>
  </section>
</section>
