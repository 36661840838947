import { GlobalService } from "./global.service";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(public http: HttpClient, public global: GlobalService) {}

  get(url: string, params = {}) {
    return this.http
      .get(this.global.apiUrl + url, {
        params: params,
      })
      .pipe(
        map((r) => r),
        catchError(this.handleError.bind(this))
      );
  }

  post(url: string, body = {}) {
    return this.http.post(this.global.apiUrl + url, body).pipe(
      map((r) => r),
      catchError(this.handleError.bind(this))
    );
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
