export class Mensagem {
    
    id: number;
    nome: string;
    email: string;
    telefone: string;
    assunto: string;
    mensagem: string;
    [x: string]: any;

    constructor(obj?) {
        Object.assign(this);
    }
}