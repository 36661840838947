import { Produto } from "./../models/produto.model";
import { Mensagem } from "./../models/mensagem.model";
import { Sobre } from "./../models/sobre.model";
import { Noticia } from "./../models/noticia.model";
import { ContatoGeral } from "./../models/contato-geral.model";
import { ApiService } from "./api.service";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Categoria } from "../models/categoria.model";
import { Subcategoria } from "../models/subcategoria.model";

@Injectable({
  providedIn: "root",
})
export class SiteService {
  constructor(public api: ApiService) {}

  buscarContatoGeral(): Observable<ContatoGeral> {
    return this.api.get("/site/contatoGeral/buscar");
  }

  buscarBanners(
    numeroPagina: number,
    registrosPorPagina: number
  ): Observable<any> {
    return this.api.get(
      `/site/banner/buscar?numeroPagina=${numeroPagina}&registrosPorPagina=${registrosPorPagina}`
    );
  }

  buscarBannerDestaque() {
    return this.api.get(`/site/banner/buscarDestaque`);
  }

  buscarNoticiasTag() {
    return this.api.get(`/site/noticia/tag`);
  }

  buscarNoticias(
    numeroPagina: number,
    registrosPorPagina: number,
    tag: string = "%"
  ): Observable<any> {
    return this.api.get(
      `/site/noticia/buscar?numeroPagina=${numeroPagina}&registrosPorPagina=${registrosPorPagina}&tag=${tag}`
    );
  }

  buscarUltimasNoticias(
    numeroPagina: number,
    registrosPorPagina: number,
    idNoticia: number,
    tag: string = "%"
  ): Observable<any> {
    return this.api.get(
      `/site/noticia/buscarUltimas?numeroPagina=${numeroPagina}&registrosPorPagina=${registrosPorPagina}&idNoticia=${idNoticia}&tag=${tag}`
    );
  }

  buscarNoticia(slug: string): Observable<Noticia> {
    return this.api.get(`/site/noticia/buscar?slug=${slug}`);
  }

  buscarFornecedores(
    numeroPagina: number,
    registrosPorPagina: number
  ): Observable<any> {
    return this.api.get(
      `/site/fornecedor/buscar?numeroPagina=${numeroPagina}&registrosPorPagina=${registrosPorPagina}`
    );
  }

  buscarDepoimentos(
    numeroPagina: number,
    registrosPorPagina: number
  ): Observable<any> {
    return this.api.get(
      `/site/depoimento/buscar?numeroPagina=${numeroPagina}&registrosPorPagina=${registrosPorPagina}`
    );
  }

  buscarCategorias(
    numeroPagina: number,
    registrosPorPagina: number
  ): Observable<any> {
    return this.api.get(
      `/site/categoria/buscar?numeroPagina=${numeroPagina}&registrosPorPagina=${registrosPorPagina}`
    );
  }

  buscarProdutos(
    numeroPagina: number,
    registrosPorPagina: number,
    ordenacao: number,
    slugCategoria: string = "",
    slugSubcategoria: string = "",
    genero: string = "",
    valorMin: any = "",
    valorMax: any = "",
    marcas: string = "",
    filtro: string = ""
  ): Observable<any> {
    const params = {
      filtro: filtro,
      numeroPagina: numeroPagina,
      registrosPorPagina: registrosPorPagina,
      ordenacao: ordenacao,
      slugCategoria: slugCategoria,
      slugSubcategoria: slugSubcategoria,
      genero: genero,
      valorMin: valorMin ? valorMin : "",
      valorMax: valorMax ? valorMax : "",
      marcas: marcas ? marcas : "",
    };
    return this.api.get("/site/produto/buscar", params);
  }

  buscarProdutosRelacionados(
    registrosPorPagina: number,
    slugCategoria: string = "",
    slugSubcategoria: string = "",
    slugProduto: string = ""
  ) {
    const params = {
      registrosPorPagina: registrosPorPagina,
      slugCategoria: slugCategoria,
      slugSubcategoria: slugSubcategoria,
      slugProduto: slugProduto,
    };
    return this.api.get(`/site/produto/buscarRelacionado`, params);
  }

  buscarProduto(slug: string): Observable<Produto> {
    return this.api.get(`/site/produto/buscar`, {
      slug,
    });
  }

  buscarMinMaxValor(
    filtro: string,
    slugCategoria: string,
    slugSubcategoria: string,
    genero: string
  ) {
    return this.api.get("/site/produto/buscarMinMaxValor", {
      filtro,
      slugCategoria,
      slugSubcategoria,
      genero,
    });
  }

  buscarMarcas(
    filtro: string,
    slugCategoria: string,
    slugSubcategoria: string,
    genero: string
  ) {
    return this.api.get("/site/produto/buscarMarcas", {
      filtro,
      slugCategoria,
      slugSubcategoria,
      genero,
    });
  }

  buscarCategoria(slug: string): Observable<Categoria> {
    return this.api.get("/site/categoria/buscar", { slug: slug });
  }

  buscarSubcategoria(
    slugCategoria: string,
    slugSubcategoria: string
  ): Observable<Subcategoria> {
    const params = {
      slugCategoria: slugCategoria,
      slugSubcategoria: slugSubcategoria,
    };
    return this.api.get("/site/subcategoria/buscar", params);
  }

  buscarFiltros(slugCategoria: string) {
    return this.api.get(
      `/site/filtro/categoria?slugCategoria=${slugCategoria}`
    );
  }

  buscarSobre(): Observable<Sobre> {
    return this.api.get(`/site/sobre/buscar`);
  }

  inserirContatoMensagem(mensagem: Mensagem): Observable<any> {
    return this.api.post(`/site/contatoMensagem/inserir`, mensagem);
  }
}
