<ngu-carousel #banner [inputs]="carouselBanner" [dataSource]="banners" (onMove)="onmoveFn($event)" class="banner">
    <ngu-item NguCarouselItem class="banner-item" *nguCarouselDef="let item; let i = index">
        <ng-container *ngIf="item.link">
            <a [href]="item.link" target="_blank">
                <img [src]="global.imageUrl+item.imagem" [alt]="item.titulo">
            </a>
        </ng-container>
        <ng-container *ngIf="!item.link">
            <img [src]="global.imageUrl+item.imagem" [alt]="item.titulo">
        </ng-container>
    </ngu-item>

    <button NguCarouselPrev class="banner-arrow banner-arrow__left" *ngIf="banners.length > 1">
        <i class="fas fa-arrow-left"></i>
    </button>

    <button NguCarouselNext class="banner-arrow banner-arrow__right" *ngIf="banners.length > 1">
        <i class="fas fa-arrow-right"></i>
    </button>
</ngu-carousel>

<section class="home-services">
    <div class="container">
        <div class="home-services-wrapper">
            <a class="box-service" title="MULHERES" *ngFor="let c of categorias" [routerLink]="['/produtos', c.slug]">
                <img [src]="global.imageUrl+c.imagem" [alt]="c.nome">
                <h2 class="box-service__title">
                    {{c.nome}}
                </h2>
            </a>
        </div>
    </div>
</section>

<section class="home-products">
    <div class="container">
        <div class="title">
            <h2>Produtos</h2>
        </div>

        <div class="category">
            <div class="category-item">
                <input type="radio" name="ordenacao" [(ngModel)]="ordenacao" (ngModelChange)="onOrdenacaoChange($event)"
                    [value]="5" id="novidades">
                <label for="novidades">Novidades</label>
            </div>
            <div class="category-item">
                <input type="radio" name="ordenacao" [(ngModel)]="ordenacao" (ngModelChange)="onOrdenacaoChange($event)"
                    [value]="3" id="menorpreco">
                <label for="menorpreco">Menor preço</label>
            </div>
            <div class="category-item">
                <input type="radio" name="ordenacao" [(ngModel)]="ordenacao" (ngModelChange)="onOrdenacaoChange($event)"
                    [value]="4" id="maiorpreco">
                <label for="maiorpreco">Maior preço</label>
            </div>
        </div>

        <div class="products-list" @list>
            <article class="products-item" *ngFor="let p of produtos; let i = index;" @items>
                <a class="products-item__image" [routerLink]="['/produto', p.slug]" [title]="p.nome">
                    <img [src]="global.imageUrl+p.imagem" [alt]="p.nome">
                </a>
                <div class="products-item__content">
                    <h3 class="products-item__title">
                        {{p.nome}}
                    </h3>
                    <strong class="products-item__price" *ngIf="p.valor > 0">
                        <ng-container *ngIf="p.valorPromocao">
                            <small>{{p.valor | currency:'BRL'}}</small>
                            {{p.valorPromocao | currency:'BRL'}}
                        </ng-container>
                        <ng-container *ngIf="!p.valorPromocao">
                            {{p.valor | currency:'BRL'}}
                        </ng-container>
                    </strong>
                    <p class="products-item__description">
                        {{p.marca | slice:0:64}}{{p.marca.length >= 64 ? '...' : ''}}
                    </p>
                    <a [routerLink]="['/produto', p.slug]" class="btn btn-default btn-small btn-block">
                        VEJA MAIS
                    </a>
                </div>
            </article>
        </div>
    </div>
</section>

<section class="special-product" [style.backgroundImage]="'url(' + global.imageUrl+bannerDestaque.imagem + ')'">
    <div class="container">
        <div class="special-product-wrapper">
            <h2>{{bannerDestaque.titulo}}</h2>
            <h3>{{bannerDestaque.descricao}}</h3>
            <a [href]="bannerDestaque.link" class="btn btn-default btn-large" *ngIf="bannerDestaque.link">
                VEJA AGORA
            </a>
        </div>
    </div>
</section>

<section class="home-blog" *ngIf="noticias.length">
    <div class="container">
        <div class="title">
            <h2>Últimas notícias</h2>
            <a routerLink="/blog" class="title-link" title="Ver últimas notícias">
                <span>Ver todas as notícias</span>
                <i class="fas fa-arrow-right"></i>
            </a>
        </div>
        <div class="blog-list">
            <div class="blog-item" *ngFor="let n of noticias; let i = index;">
                <a [routerLink]="['/blog', n.slug]" class="blog-item__image" [title]="n.titulo">
                    <img [src]="global.imageUrl+n.imagemPequena" [alt]="n.titulo">
                </a>
                <div class="blog-item__content">
                    <h3 class="blog-item__title">
                        {{n.titulo}}
                    </h3>
                    <p class="blog-item__description">
                        {{n.descricaoSimples | slice:0:60}}{{n.descricaoSimples.length >= 60 ? '...' : ''}}
                    </p>
                    <a [routerLink]="['/blog', n.slug]" class="btn btn-small btn-default">VEJA MAIS</a>
                </div>
            </div>
        </div>
    </div>
</section>