import { Router } from "@angular/router";
import { Categoria } from "./../../models/categoria.model";
import { ContatoGeral } from "./../../models/contato-geral.model";
import { GlobalService } from "./../../services/global.service";
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
  AfterViewInit,
  Input,
} from "@angular/core";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  @ViewChild("header") headerElement: ElementRef;
  showSearch: boolean = false;
  active: boolean = false;
  openMenu: boolean = false;
  openCategories: boolean = false;
  openFilter: boolean = false;
  submenuSelected = "";

  search: string = "";

  @HostListener("window:resize", [])
  handleResize(): void {
    const headerHeight = this.headerElement.nativeElement.offsetHeight;
    this.global.headerHeight = `${headerHeight}px`;
  }
  @HostListener("window:scroll", [])
  handleScroll(): void {
    const windowScroll = window.pageYOffset;
    if (windowScroll >= 50) {
      this.active = true;
    } else {
      this.active = false;
    }
  }

  @Input("contatoGeral") contatoGeral: ContatoGeral;
  @Input("categorias") categorias: Categoria[];

  constructor(public global: GlobalService, public router: Router) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.global.headerHeight = `${this.headerElement.nativeElement.offsetHeight}px`;
  }

  buscar() {
    this.router
      .navigate(["/buscar"], {
        queryParams: { search: this.search },
      })
      .then(() => (this.search = ""));
  }

  closeAll() {
    this.openMenu = false;
    this.openCategories = false;
  }
}
