import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { LoadingService } from './../../services/loading.service';
import { SiteService } from './../../services/site.service';
import { Sobre } from './../../models/sobre.model';
import { Depoimento } from './../../models/depoimento.model';
import { Fornecedor } from './../../models/fornecedor.model';
import { Component, OnInit } from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';
import { Subscription, forkJoin } from 'rxjs';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-sobre',
  templateUrl: './sobre.component.html',
  styleUrls: ['./sobre.component.scss']
})
export class SobreComponent implements OnInit {

  carouselBanner: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 2, lg: 2, all: 0 },
    slide: 2,
    speed: 500,
    interval: {
      timing: 3000,
      initialDelay: 1000
    },
    point: {
      visible: true
    },
    load: 1,
    loop: true,
    touch: true,
    easing: 'cubic-bezier(0, 0, 0.2, 1)'
  };

  sobre: Sobre = new Sobre();
  depoimentos: Depoimento[] = [];
  fornecedores: Fornecedor[] = [];

  forkSubscription: Subscription;

  html: SafeHtml;

  constructor(
    public siteService: SiteService,
    public loadingService: LoadingService,
    public sanitizer: DomSanitizer,
    public global: GlobalService,
  ) { }

  ngOnInit() {
    let buscarSobre = this.siteService.buscarSobre();
    let buscarDepoimentos = this.siteService.buscarDepoimentos(-99, -99);
    let buscarFornecedores = this.siteService.buscarFornecedores(-99, -99);

    this.fork([buscarSobre, buscarDepoimentos, buscarFornecedores]);
  }

  ngOnDestroy() {
    this.forkSubscription.unsubscribe();
  }

  fork(reqs) {
    this.loadingService.present();
    this.forkSubscription = forkJoin(reqs)
      .subscribe((res: any) => {
        this.sobre = res[0];
        this.html = this.sanitizer.bypassSecurityTrustHtml(res[0].descricao);
        this.depoimentos = res[1];
        this.fornecedores = res[2];
        this.loadingService.dismiss();
      }, e => this.loadingService.dismiss())
  }

}
