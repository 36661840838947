import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  apiUrl: string = 'https://api.foquebem.com.br';
  imageUrl: string = 'https://api.foquebem.com.br/';
  headerHeight: string = '0px';

  constructor() { }
}
