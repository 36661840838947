<app-header-interno [background]="'assets/banner-interno-contato.jpg'" [title]="'Contato'"></app-header-interno>

<section class="contato">
    <div class="container">
        <div class="contato-wrapper">
            <div class="contato-info">
                <div class="contato-info-item">
                    <div class="contato-info-item__icon">
                        <i class="fas fa-map-marker-alt"></i>
                    </div>
                    <div class="contato-info-item__content">
                        <h3 class="contato-info-item__title">Endereço</h3>
                        <p class="contato-info-item__description">
                            {{contatoGeral.rua}}, {{contatoGeral.numeroEndereco}}, {{contatoGeral.bairro}}, Brasil -
                            {{contatoGeral.cidade}}/{{contatoGeral.uf}}
                        </p>
                    </div>
                </div>
                <div class="contato-info-item">
                    <div class="contato-info-item__icon">
                        <i class="fas fa-phone-alt"></i>
                    </div>
                    <div class="contato-info-item__content">
                        <h3 class="contato-info-item__title">Telefone</h3>
                        <a [href]="'tel:55'+contatoGeral.telefone" class="contato-info-item__link">
                            {{contatoGeral.telefone | mask: contatoGeral.telefone.length == 11 ? '(00) 00000-0000' : '(00) 0000-0000'}}
                        </a>
                    </div>
                </div>
                <div class="contato-info-item">
                    <div class="contato-info-item__icon">
                        <i class="fas fa-envelope"></i>
                    </div>
                    <div class="contato-info-item__content">
                        <h3 class="contato-info-item__title">E-mail</h3>
                        <a [href]="'mailto:'+contatoGeral.email"
                            class="contato-info-item__link">{{contatoGeral.email}}</a>
                    </div>
                </div>
            </div>

            <div class="contato-form">
                <div class="title">
                    <h2>Entre em contato</h2>
                </div>
                <form (ngSubmit)="enviarMensagem(form)" #form="ngForm" class="form">
                    <div class="form-control half">
                        <input type="text" name="nome" #nomeInput="ngModel" [(ngModel)]="mensagem.nome"
                            placeholder="Seu nome" autocomplete="name" required>
                        <span class="form-control__hint" *ngIf="nomeInput.invalid && nomeInput.touched">
                            *Preencha este campo.
                        </span>
                    </div>
                    <div class="form-control half">
                        <input type="email" name="email" #emailInput="ngModel" [(ngModel)]="mensagem.email"
                            placeholder="E-mail" autocomplete="email" required>
                        <span class="form-control__hint" *ngIf="emailInput.invalid && emailInput.touched">
                            *Preencha este campo.
                        </span>
                    </div>
                    <div class="form-control half">
                        <input type="tel" name="telefone" #telefoneInput="ngModel" [(ngModel)]="mensagem.telefone"
                            placeholder="Telefone" autocomplete="tel" [mask]="phoneMask" [dropSpecialCharacters]="false"
                            (ngModelChange)="changePhoneMask($event)" required>
                        <span class="form-control__hint" *ngIf="telefoneInput.invalid && telefoneInput.touched">
                            *Preencha este campo.
                        </span>
                    </div>
                    <div class="form-control half">
                        <input type="text" name="assunto" #assuntoInput="ngModel" [(ngModel)]="mensagem.assunto"
                            placeholder="Assunto" required>
                        <span class="form-control__hint" *ngIf="assuntoInput.invalid && assuntoInput.touched">
                            *Preencha este campo.
                        </span>
                    </div>
                    <div class="form-control">
                        <textarea name="mensagem" #mensagemInput="ngModel" [(ngModel)]="mensagem.mensagem" id="mensagem"
                            placeholder="Digite uma mensagem" required></textarea>
                        <span class="form-control__hint" *ngIf="mensagemInput.invalid && mensagemInput.touched">
                            *Preencha este campo.
                        </span>
                    </div>
                    <div class="form-button">
                        <button class="btn btn-default" type="submit">ENVIAR</button>
                    </div>
                </form>
            </div>

            <div #mapContainer id="map"></div>
        </div>
    </div>
</section>