import {
  Meta,
  Title,
  DomSanitizer,
  SafeHtml,
  SafeUrl,
} from "@angular/platform-browser";
import { LoadingService } from "./../../services/loading.service";
import { HelperService } from "./../../services/helper.service";
import { SiteService } from "./../../services/site.service";
import { Produto } from "./../../models/produto.model";
import { Component, OnInit } from "@angular/core";
import { NguCarouselConfig } from "@ngu/carousel";
import { ActivatedRoute, Router } from "@angular/router";
import { Galeria } from "src/app/models/galeria.model";
import { GlobalService } from "src/app/services/global.service";

const formato = {
  minimumFractionDigits: 2,
  style: "currency",
  currency: "BRL",
};

@Component({
  selector: "app-produto-interno",
  templateUrl: "./produto-interno.component.html",
  styleUrls: ["./produto-interno.component.scss"],
})
export class ProdutoInternoComponent implements OnInit {
  carouselBanner: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
    slide: 1,
    speed: 500,
    point: {
      visible: true,
    },
    load: 1,
    loop: false,
    touch: false,
    easing: "cubic-bezier(0, 0, 0.2, 1)",
  };

  produto: Produto = new Produto();
  produtos: Produto[] = [];

  shareUrl: string = "https://foquebem.com.br";

  html: SafeHtml;
  link: SafeUrl;

  imageSelected: Galeria = new Galeria();

  constructor(
    public siteService: SiteService,
    public helper: HelperService,
    public loadingService: LoadingService,
    public router: Router,
    public route: ActivatedRoute,
    public meta: Meta,
    public title: Title,
    public sanitizer: DomSanitizer,
    public global: GlobalService
  ) {}

  ngOnInit() {
    this.shareUrl += this.router.url;
    this.route.params.subscribe((param) => {
      this.buscarProduto(param.slug);
    });
    this.helper.setCanonicalURL();
  }

  ngOnDestroy() {
    this.helper.updateTags();
  }

  openWhatsapp() {
    if (typeof window !== undefined) {
      const text = `Olá, vim pelo Site. Gostaria de comprar este produto! Você pode me ajudar?\n\n${this.shareUrl}`;
      window.open(
        `${this.produto.link}?text=${window.encodeURIComponent(text)}`,
        "_blank"
      );
    }
  }

  buscarProduto(slug: string) {
    this.loadingService.present();
    this.siteService.buscarProduto(slug).subscribe((res) => {
      this.produto = res;
      this.buscarProdutosRelacionados();
      if (this.produto.imagens.length) {
        this.imageSelected = this.produto.imagens[0];
      } else {
        this.imageSelected.imagem = this.produto.imagem;
        const item = {
          id: 0,
          imagem: this.produto.imagem,
          nome: "Imagem",
        } as Galeria;
        this.produto.imagens.push(item);
      }
      if (this.produto.link) {
        this.link = this.sanitizer.bypassSecurityTrustResourceUrl(
          this.produto.link
        );
      }
      this.html = this.sanitizer.bypassSecurityTrustHtml(
        this.produto.descricaoCompleta
      );
      this.helper.updateTags({
        nome: `${this.produto.nome} - FOQUEBEM Óptica`,
        descricaoSimples: `Compre ${
          this.produto.nome
        } - R$ ${this.produto.valor.toLocaleString("pt-Br", formato)}`,
        imagem: this.imageSelected.imagem,
      });
      this.loadingService.dismiss();
    });
  }

  buscarProdutosRelacionados() {
    const subcategoria = this.produto?.subcategorias?.[0]?.subcategoria;
    this.siteService
      .buscarProdutosRelacionados(
        4,
        subcategoria?.categoria?.slug,
        subcategoria?.slug,
        this.produto.slug
      )
      .subscribe((res) => (this.produtos = res));
  }
}
