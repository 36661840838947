<header class="header" #header>
    <nav class="header-nav">
        <div class="wrapper">
            <a class="logo" routerLink="/" title="FOQUEBEM Óptica">
                <img src="assets/logo.png" alt="FOQUEBEM Óptica" title="FOQUEBEM Óptica">
            </a>
            <button class="header-menu-toggle" (click)="openMenu = !openMenu">
                <i class="fas fa-bars"></i>
            </button>
            <ul class="header-menu" [class.active]="openMenu">
                <li class="header-menu__item">
                    <a routerLinkActive="active" routerLink="/inicio" class="header-menu__link"
                        (click)="openMenu = false" title="Início">Início</a>
                </li>
                <li class="header-menu__item">
                    <a routerLinkActive="active" routerLink="/sobre" class="header-menu__link"
                        (click)="openMenu = false" title="Sobre">Sobre</a>
                </li>
                <li class="header-menu__item">
                    <a routerLinkActive="active" routerLink="/blog" class="header-menu__link" (click)="openMenu = false"
                        title="Blog">Blog</a>
                </li>
                <li class="header-menu__item">
                    <a routerLinkActive="active" routerLink="/contato" class="header-menu__link"
                        (click)="openMenu = false" title="Contato">Contato</a>
                </li>
            </ul>
            <div class="header-search" [class.active]="showSearch">
                <form (ngSubmit)="buscar()">
                    <input type="search" placeholder="O que você procura?" [(ngModel)]="search" name="search">
                </form>
                <i class="fas fa-search hidden-mobile"></i>
                <i [class]="showSearch ? 'hidden-desktop fas fa-times' : 'hidden-desktop fas fa-search'"
                    (click)="showSearch = !showSearch"></i>
            </div>
        </div>
        <div class="categories">
            <button class="categories-toggle hidden-desktop" (click)="openCategories = !openCategories">
                <i class="fas fa-filter"></i>
            </button>
            <div class="categories-menu" [class.active]="openCategories">
                <div class="categories-item" *ngFor="let c of categorias" [class.active]="submenuSelected === c.nome">
                    <a class="categories-item__link hidden-mobile" [routerLink]="['/produtos', c.slug]">
                        {{c?.nome}}
                    </a>
                    <a class="categories-item__link hidden-desktop" (click)="submenuSelected = c.nome">
                        {{c?.nome}}
                    </a>
                    <ng-container *ngIf="c.subcategorias.length > 1">
                        <ul class="submenu hidden-desktop"
                            [@detailExpand]="submenuSelected === c.nome ? 'expanded' : 'collapsed'">
                            <li *ngFor="let s of c.subcategorias" class="submenu-item">
                                <a [title]="c.nome" [routerLink]="['/produtos', c.slug, s.slug]"
                                    (click)="openCategories = false">
                                    {{s.nome}}
                                </a>
                            </li>
                            <li *ngFor="let s of c.subcategorias" class="submenu-item">
                                <a title="todos" [routerLink]="['/produtos', c.slug]" class="hidden-desktop"
                                    (click)="openCategories = false">
                                    Todos
                                </a>
                            </li>
                        </ul>
                        <ul class="submenu hidden-mobile">
                            <li *ngFor="let s of c.subcategorias" class="submenu-item">
                                <a [title]="c.nome" [routerLink]="['/produtos', c.slug, s.slug]"
                                    (click)="openCategories = false">
                                    {{s.nome}}
                                </a>
                            </li>
                        </ul>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="header-backdrop" [class.active]="openMenu || openCategories" (click)="closeAll()"></div>
    </nav>
</header>