import { Injectable, Inject } from "@angular/core";
import * as moment from "moment";
import { NgForm } from "@angular/forms";
import { DOCUMENT } from "@angular/common";
import { GlobalService } from "./global.service";
import { Title, Meta } from "@angular/platform-browser";

export interface MetaTags {
  nome: string;
  descricaoSimples: string;
  imagem: string;
}

@Injectable({
  providedIn: "root",
})
export class HelperService {
  moment;
  emailRegex: RegExp;

  constructor(
    @Inject(DOCUMENT) private dom,
    public global: GlobalService,
    public _meta: Meta,
    public _title: Title
  ) {
    moment.locale("pt-Br");
    this.moment = moment;
    this.emailRegex = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  }

  formMarkAllTouched(form: NgForm) {
    (<any>Object).values(form.controls).forEach((control) => {
      control.markAsTouched();
      if (control.controls) {
        (<any>Object)
          .values(control.controls)
          .forEach((c) => this.formMarkAllTouched(c));
      }
    });
  }

  getYoutubeId(url: string) {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  }

  setCanonicalURL(url?: string) {
    const canURL = url == undefined ? this.dom.URL : url;
    const link: HTMLLinkElement = this.dom.createElement("link");
    link.setAttribute("rel", "canonical");
    this.dom.head.appendChild(link);
    link.setAttribute("href", canURL);
  }

  renderImage(path: string, width: number): string {
    return `${this.global.apiUrl}/site/imagem/renderizar?path=${path}&width=${width}`;
  }

  updateTags(obj?: MetaTags) {
    let res: MetaTags = {
      nome: "FOQUEBEM Óptica | Muito além do seu olhar!",
      descricaoSimples:
        "Relógios, Semi-Joias, Alianças de Ouro e Consertos em Geral.",
      imagem: "https://foquebem.com.br/assets/avatar.png",
    };
    if (obj) res = obj;
    const shareUrl: string = `https://tbbrumstore.com.br`;
    this._title.setTitle(res.nome);
    this._meta.updateTag({ name: "title", content: res.nome });
    this._meta.updateTag({ name: "subject", content: res.nome });
    this._meta.updateTag({
      name: "description",
      content: res.descricaoSimples,
    });
    this._meta.updateTag({ name: "url", content: shareUrl });
    this._meta.updateTag({ name: "image", content: res.imagem });
    this._meta.updateTag({ property: "og:title", content: res.nome });
    this._meta.updateTag({
      property: "og:description",
      content: res.descricaoSimples,
    });
    this._meta.updateTag({ property: "og:url", content: shareUrl });
    this._meta.updateTag({ property: "og:image", content: res.imagem });
    this._meta.updateTag({
      property: "og:image:secure_url",
      content: res.imagem,
    });
    this._meta.updateTag({ property: "og:image:alt", content: res.nome });
    this._meta.updateTag({ name: "twitter:title", content: res.nome });
    this._meta.updateTag({
      name: "twitter:description",
      content: res.descricaoSimples,
    });
    this._meta.updateTag({ name: "twitter:url", content: shareUrl });
    this._meta.updateTag({ name: "twitter:image", content: res.imagem });
    this._meta.updateTag({ name: "twitter:image:src", content: res.imagem });
  }
}
