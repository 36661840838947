import { Produto } from "./../../models/produto.model";
import { Categoria } from "./../../models/categoria.model";
import { HelperService } from "./../../services/helper.service";
import { Noticia } from "./../../models/noticia.model";
import { Banner } from "./../../models/banner.model";
import { SiteService } from "./../../services/site.service";
import { Subscription, forkJoin } from "rxjs";
import { LoadingService } from "./../../services/loading.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import {
  NguCarouselStore,
  NguCarousel,
  NguCarouselConfig,
} from "@ngu/carousel";
import {
  trigger,
  style,
  transition,
  animate,
  query,
  stagger,
  animateChild,
} from "@angular/animations";
import { GlobalService } from "src/app/services/global.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
  animations: [
    trigger("items", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate("300ms ease-in-out"),
      ]),
    ]),
    trigger("list", [
      transition(":enter", [
        query("@items", stagger(300, animateChild()), { optional: true }),
      ]),
    ]),
  ],
})
export class HomeComponent implements OnInit {
  @ViewChild("banner") carousel: NguCarousel<any>;

  carouselBanner: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
    slide: 1,
    speed: 500,
    interval: {
      timing: 5000,
      initialDelay: 1000,
    },
    point: {
      visible: true,
    },
    load: 1,
    loop: true,
    touch: false,
    easing: "cubic-bezier(0, 0, 0.2, 1)",
  };

  categorias: Categoria[] = [];
  banners: Banner[] = [];
  noticias: Noticia[] = [];
  produtos: Produto[] = [];
  bannerDestaque: Banner = new Banner();

  loading: boolean = false;

  forkSubscription: Subscription;
  buscarProdutosSubscription: Subscription;

  ordenacao: number = 5;

  constructor(
    public loadingService: LoadingService,
    public siteService: SiteService,
    public helper: HelperService,
    public global: GlobalService
  ) { }

  ngOnInit() {
    this.fork();
  }

  ngOnDestroy() {
    this.forkSubscription.unsubscribe();
  }

  fork() {
    const reqs = [
      this.siteService.buscarBanners(-99, -99),
      this.siteService.buscarNoticias(1, 3),
      this.siteService.buscarCategorias(1, 3),
      this.siteService.buscarProdutos(1, 8, this.ordenacao),
      this.siteService.buscarBannerDestaque(),
    ];
    this.loadingService.present();
    this.forkSubscription = forkJoin(reqs).subscribe(
      (res: any) => {
        this.banners = res[0];
        this.noticias = res[1];
        this.categorias = res[2];
        this.produtos = res[3].produtos;
        this.bannerDestaque = res[4];
        this.loadingService.dismiss();
      },
      (e) => this.loadingService.dismiss()
    );
  }

  onmoveFn(data: NguCarouselStore) { }

  onOrdenacaoChange(ev) {
    this.buscarProdutos();
  }

  buscarProdutos() {
    if (
      this.buscarProdutosSubscription &&
      !this.buscarProdutosSubscription.closed
    ) {
      this.buscarProdutosSubscription.unsubscribe();
    }

    this.loading = true;

    this.buscarProdutosSubscription = this.siteService
      .buscarProdutos(1, 8, this.ordenacao)
      .subscribe(
        (res: any) => {
          this.produtos = res.produtos;
          this.loading = false;
        },
        (e) => (this.loading = false)
      );
  }
}
