<app-header-interno [title]="noticia.titulo"></app-header-interno>

<section class="blog-interno">
    <div class="container">
        <div class="blog-interno__image">
            <img [src]="noticia.imagemGrande" [alt]="noticia.titulo">
            <ul class="social-share">
                <li class="social-share__item">
                    <a [href]="'http://www.facebook.com/sharer.php?u=' + shareUrl"
                        class="social-share__link social-share__link--facebook" target="_blank">
                        <i class="fab fa-facebook-f"></i>
                    </a>
                </li>
                <li class="social-share__item">
                    <a [href]="'https://twitter.com/intent/tweet?url=' + shareUrl"
                        class="social-share__link social-share__link--twitter" target="_blank">
                        <i class="fab fa-twitter"></i>
                    </a>
                </li>
                <li class="social-share__item">
                    <a class="social-share__link social-share__link--linkedin"
                        [href]="'http://www.linkedin.com/shareArticle?mini=true&amp;url=' + shareUrl" target="_blank">
                        <i class="fab fa-linkedin"></i>
                    </a>
                </li>
                <li class="social-share__item">
                    <a [href]="'mailto:contato@foquebem.com.br?subject=Contato Via Optica Skalla&amp;body=Você visualizou uma notícia em nossa página. segue o endereço. ' + shareUrl"
                        class="social-share__link social-share__link--mail">
                        <i class="fas fa-envelope"></i>
                    </a>
                </li>
            </ul>
        </div>

        <div class="blog-interno-info">
            <div class="blog-interno-info__item">
                <i class="fas fa-calendar-alt"></i>
                <span>{{helper.moment(noticia.data).format('DD')}} de {{helper.moment(noticia.data).format('MMMM')}},
                    {{helper.moment(noticia.data).format('YYYY')}}</span>
            </div>
            <div class="blog-interno-info__item">
                <i class="fas fa-user"></i>
                <span>{{noticia.autor}}</span>
            </div>
        </div>

        <div class="blog-interno__description summernote" [innerHtml]="html"></div>

        <div class="clear-fix"></div>

        <div class="blog-related">
            <div class="blog-related__title title title-default">
                <h2>Notícias relacionadas</h2>
                <span>Veja algumas notícias parecidas com essa.</span>
            </div>
            <div class="blog-list">
                <div class="blog-item" *ngFor="let n of noticias; let i = index;" [@simpleFadeAnimation]="'in'">
                    <a [routerLink]="['/blog', n.slug]" class="blog-item__image" [title]="n.titulo">
                        <img [src]="n.imagemPequena" [alt]="n.titulo">
                    </a>
                    <div class="blog-item__content">
                        <h3 class="blog-item__title">
                            {{n.titulo}}
                        </h3>
                        <p class="blog-item__description">
                            {{n.descricaoSimples | slice:0:60}}{{n.descricaoSimples.length >= 60 ? '...' : ''}}
                        </p>
                        <a [routerLink]="['/blog', n.slug]" class="btn btn-small btn-default">VEJA MAIS</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>