<app-header-interno [background]="imagemFundo" [title]="titulo" [hideTitle]="hideTitle"></app-header-interno>

<section class="produtos">
  <div class="container">
    <div class="produtos-wrapper">
      <div class="left">
        <button class="filter-toggle" (click)="openFilter = !openFilter">
          <i class="fas fa-filter"></i>
          <span>FILTROS</span>
        </button>
        <div class="filter" [class.active]="openFilter">
          <div class="filter-item">
            <h4 class="filter-item__title">Gênero</h4>
            <ul class="filter-item__menu">
              <li *ngFor="let item of generos">
                <input type="radio" name="genero" [id]="item?.id" [value]="item.value" [(ngModel)]="genero"
                  (ngModelChange)="onChangeGenero($event)" />
                <label [for]="item.id">{{ item.name }}</label>
              </li>
              <sub *ngIf="genero">
                <a (click)="clearFilter('genero')">Limpar filtro</a>
              </sub>
            </ul>
          </div>
          <div class="filter-item" *ngIf="options.ceil && options.floor">
            <h4 class="filter-item__title">Valor</h4>
            <ngx-slider [(value)]="valorMin" [(highValue)]="valorMax" [options]="options"
              (userChangeEnd)="onSliderChange($event)">
            </ngx-slider>
          </div>
          <div class="filter-item">
            <h4 class="filter-item__title">Marcas</h4>
            <ul class="filter-item__menu">
              <ng-container *ngFor="let item of marcas; let i = index">
                <li *ngIf="i <= 12 || showMoreMarcas">
                  <input type="checkbox" name="marca" [id]="'marca-' + i" [value]="item.label"
                    [(ngModel)]="item.checked" (ngModelChange)="onChagenMarca(item)" />
                  <label [for]="'marca-' + i">{{ item?.label }}</label>
                </li>
              </ng-container>
              <sub *ngIf="!showMoreMarcas">
                <a (click)="showMoreMarcas = !showMoreMarcas">Carregar mais...</a>
              </sub>
              <sub *ngIf="marcasSelected.length">
                <a (click)="clearFilter('marca')">Limpar filtro</a>
              </sub>
            </ul>
          </div>
        </div>
        <div class="filter-backdrop" [class.active]="openFilter" (click)="openFilter = false"></div>
      </div>
      <div class="right">
        <div class="products-filter">
          <span>Nós encontramos <b>{{ totalProdutos }} produtos</b> disponíveis
            para você.</span>
          <div class="products-filter__select">
            <label for="ordenacao">Ordenar por: </label>
            <select name="ordenacao" id="ordenacao" [(ngModel)]="ordenacao" (ngModelChange)="onOrdenacaoChange($event)">
              <option value="5">Novidades</option>
              <option value="3">Menor preço</option>
              <option value="4">Maior preço</option>
              <option value="1">A-Z</option>
              <option value="2">Z-A</option>
            </select>
          </div>
        </div>
        <div class="products-list" @list *ngIf="!loading || produtos.length">
          <!-- [class.quad]="!filtro.configuracao.variaveis.length" -->
          <article class="products-item" *ngFor="let p of produtos; let i = index" @items>
            <a class="products-item__image" [routerLink]="['/produto', p.slug]" [title]="p.nome">
              <img [src]="global.imageUrl + p.imagem" [alt]="p.nome" />
            </a>
            <div class="products-item__content">
              <h3 class="products-item__title">
                {{ p.nome }}
              </h3>
              <strong class="products-item__price" *ngIf="p.valor > 0">
                <ng-container *ngIf="p.valorPromocao">
                  <small>{{ p.valor | currency: "BRL" }}</small>
                  {{ p.valorPromocao | currency: "BRL" }}
                </ng-container>
                <ng-container *ngIf="!p.valorPromocao">
                  {{ p.valor | currency: "BRL" }}
                </ng-container>
              </strong>
              <p class="products-item__description">
                {{ p.marca | slice: 0:64
                }}{{ p.marca.length >= 64 ? "..." : "" }}
              </p>
              <a [routerLink]="['/produto', p.slug]" class="btn btn-default btn-small btn-block">
                VEJA MAIS
              </a>
            </div>
          </article>
        </div>

        <app-loading-list class="products-loading" *ngIf="loading"></app-loading-list>

        <div class="products-list__button" *ngIf="!noMore && !loading">
          <a (click)="loadMore()" class="btn btn-outlined" title="ver mais">
            ver mais
          </a>
        </div>
      </div>
    </div>
  </div>
</section>