import { NgModule, LOCALE_ID } from "@angular/core";
// pages
import { AppComponent } from "./app.component";
import { HomeComponent } from "./pages/home/home.component";
import { ProdutosComponent } from "./pages/produtos/produtos.component";
import { ProdutoInternoComponent } from "./pages/produto-interno/produto-interno.component";
import { BlogComponent } from "./pages/blog/blog.component";
import { BlogInternoComponent } from "./pages/blog-interno/blog-interno.component";
import { ContatoComponent } from "./pages/contato/contato.component";
import { SobreComponent } from "./pages/sobre/sobre.component";
// components
import { HeaderInternoComponent } from "./components/header-interno/header-interno.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { LoadingComponent } from "./components/loading/loading.component";
import { AlertComponent } from "./components/alert/alert.component";
import { LoadingListComponent } from "./components/loading-list/loading-list.component";
// imports
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { NguCarouselModule } from "@ngu/carousel";
import { NgxMaskModule } from "ngx-mask";
import { NgxImageZoomModule } from "ngx-image-zoom";

import localePt from "@angular/common/locales/pt";
import { registerLocaleData } from "@angular/common";
import { BuscarComponent } from "./pages/buscar/buscar.component";
import { WhatsappButtonComponent } from "./components/whatsapp-button/whatsapp-button.component";
import { NgxSliderModule } from "@angular-slider/ngx-slider";
registerLocaleData(localePt);

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        HeaderComponent,
        FooterComponent,
        ProdutosComponent,
        HeaderInternoComponent,
        ProdutoInternoComponent,
        BlogComponent,
        BlogInternoComponent,
        ContatoComponent,
        SobreComponent,
        // components
        HeaderComponent,
        HeaderInternoComponent,
        FooterComponent,
        AlertComponent,
        LoadingComponent,
        LoadingListComponent,
        BuscarComponent,
        WhatsappButtonComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        NguCarouselModule,
        NgxMaskModule.forRoot(),
        NgxImageZoomModule,
        NgxSliderModule,
    ],
    providers: [{ provide: LOCALE_ID, useValue: "pt-BR" }],
    bootstrap: [AppComponent]
})
export class AppModule {}
