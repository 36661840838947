import { Categoria } from "./models/categoria.model";
import { GlobalService } from "./services/global.service";
import { AlertService } from "./services/alert.service";
import { HelperService } from "./services/helper.service";
import { SiteService } from "./services/site.service";
import { ContatoGeral } from "./models/contato-geral.model";
import { Component, OnInit } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";

declare const gtag: Function; // <------------Important: the declartion for gtag is required!

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  contatoGeral: ContatoGeral = new ContatoGeral();
  categorias: Categoria[] = [];

  constructor(
    public meta: Meta,
    public title: Title,
    public siteService: SiteService,
    public helper: HelperService,
    public alertService: AlertService,
    public global: GlobalService,
    private router: Router
  ) {
    this.helper.updateTags();
    /** START : Code to Track Page View using gtag.js */
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        gtag("event", "page_view", {
          page_path: event.urlAfterRedirects,
        });
      });
    /** END : Code to Track Page View  using gtag.js */
  }

  ngOnInit() {
    this.buscarContatoGeral();
    this.buscarCategorias();
  }

  buscarContatoGeral() {
    this.siteService
      .buscarContatoGeral()
      .subscribe((res: ContatoGeral) => (this.contatoGeral = res));
  }

  buscarCategorias() {
    this.siteService
      .buscarCategorias(-99, -99)
      .subscribe((res: Categoria[]) => (this.categorias = res));
  }
}
